import React from 'react';
import Features from "./section/features";
import ConsultationInLine from "./section/consultation-in-line";
import Hero2 from "./section/hero-v2";
import Header from "../shared/section/header";
import Footer from "../shared/section/footer";
import Faq from "../shared/section/faq";
import ReasonsForConsultation from "./section/Reasons-for-consultation";
import ContactForm from "../contact/section/contact-form";

export default function Home() {
  return (
      <div>
          <Header/>
          <Hero2/>
          <ReasonsForConsultation/>
          <Features/>
          <ConsultationInLine/>
          <Faq/>
          <ContactForm/>
          <Footer/>
      </div>
  )
}