import React from 'react';
import {faqs} from './faq.model'
import {Disclosure} from "@headlessui/react";
import {MinusSmallIcon, PlusSmallIcon} from "@heroicons/react/20/solid";

const backgroundTop = (<div
    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    aria-hidden="true"
>
    <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
            clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
    />
</div>)


const backgroundBottom = (
    <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
    >
        <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
                clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
        />
    </div>
)

export default function Faq() {
    return (
        <div className="bg-white relative isolate">
            {backgroundTop}
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                    Questions Fréquentes (FAQ) - Psychopraticienne
                </h2>
                <p className="mt-4 text-base leading-7 text-gray-600">
                    Vous avez des questions sur le processus thérapeutique ?
                    Découvrez les réponses aux questions les plus courantes ci-dessous.
                    Si vous ne trouvez pas la réponse à votre question, n'hésitez pas à me contacter directement.
                    Je suis là pour vous aider à mieux comprendre et à vous sentir à l'aise dans votre démarche
                    vers le bien-être mental et émotionnel.
                </p>
                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                    {faqs.map((faq) => (
                        <Disclosure as="div" key={faq.question} className="pt-6">
                            {({open}) => (
                                <>
                                    <dt>
                                        <Disclosure.Button
                                            className="flex w-full items-start justify-between text-left text-gray-900">
                                            <span className="text-base font-semibold leading-7">{faq.question}</span>
                                            <span className="ml-6 flex h-7 items-center">
                          {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true"/>
                          ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true"/>
                          )}
                        </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </dl>
            </div>
            <div className="flex items-center gap-x-6 mt-6 justify-center">
                <a
                    href="https://calendly.com/alex-campari-psy"
                    className="rounded-md bg-orange-900 px-3.5 py-2.5 text-sm
                            font-semibold text-white shadow-sm hover:bg-orange-800
                            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                            focus-visible:outline-blue-600 sm:text-2xl"
                >
                    Prendre Rendez-vous
                </a>
            </div>
            {backgroundBottom}
        </div>
    )
}
