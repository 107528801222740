import React from 'react';
import Header from "../shared/section/header";
import Footer from "../shared/section/footer";
import DataRDV from "../rdv/section/data-rdv";

export default function RDV() {
    return (
        <div>
            <Header />
            <DataRDV/>
            <Footer />
        </div>
    )
}