import React from 'react';

function twoHands() {
    return <img
        src="/poignee-de-main.png"
        alt="App screenshot"
        width={1432}
        height={800}
        className="w-[40rem]"
    />
}
function conversation() {
    return <img
        src="/messagerie.png"
        alt="App screenshot"
        width={1432}
        height={800}
        className="w-[40rem]"
    />
}

function share() {
    return <img
        src="/puzzle.png"
        alt="App screenshot"
        width={1432}
        height={800}
        className="w-[40rem]"
    />
}
function kindness() {
    return <img
        src="/coeur.png"
        alt="App screenshot"
        width={1432}
        height={800}
        className="w-[40rem]"
    />
}


const features = [
    {
        name: 'Rencontrons-nous',
        description: 'Votre parcours vers le bien-être commence par cette première rencontre. ' +
            'Découvrons comment je peux vous accompagner dans votre chemin vers une vie plus épanouissante.',
        icon: twoHands,
    },
    {
        name: 'Échangez en toute confiance pour évoluer',
        description:
            'La clé du bien-être mental commence par une conversation ouverte et bienveillante. ' +
            'Ensemble, explorons votre monde intérieur pour vous aider à grandir et à prospérer.',
        icon: conversation,
    },
    {
        name: 'Construisons ensemble',
        description: 'Chaque séance est une pierre angulaire pour bâtir une vie plus équilibrée et épanouissante. ' +
            'Ensemble, nous façonnons votre chemin vers le mieux-être mental et émotionnel.',
        icon: share,
    },
    {
        name: 'Accompagnement bienveillant',
        description: 'La bienveillance est au cœur de ma pratique.' +
            ' Je m\'engage à vous accueillir sans jugement, à vous écouter avec attention' +
            ' et à vous accompagner dans votre cheminement vers le bien-être mental et émotionnel. ' +
            'Chaque individu a une histoire unique et des besoins spécifiques, ' +
            'et je suis là pour personnaliser notre travail en fonction de vos objectifs et de vos défis personnels.',
        icon: kindness,
    },
]


const backgroundTop = (
    <div
    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    aria-hidden="true"
>
    <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
            clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
    />
</div>)

const backgroundBottom = (
    <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
    >
        <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
                clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
        />
    </div>
)

export default function Example() {
    return (
            <div className="relative">
                {backgroundTop}
                <div className="mx-auto max-w-7xl px-6 lg:px-8 mb-20">
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                        <h1 className="mt-10 text-l font-bold tracking-tight text-gray-900 sm:text-6xl mb-20">
                            Collaboration et Confiance pour une Évolution Bienveillante
                        </h1>
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
                {backgroundBottom}
        </div>
    )
}
