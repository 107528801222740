import React from 'react';
import Header from "../shared/section/header";
import Footer from "../shared/section/footer";
import DataWhoAmI from "../who-am-i/section/Data-who-am-i"

export default function WhoAmI() {
    return (
        <div>
            <Header />
            <DataWhoAmI/>
            <Footer />
        </div>
    )
}