import React from 'react';

const backgroundTop = (<div
    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    aria-hidden="true"
>
    <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
            clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
    />
</div>)

const backgroundBottom = (
    <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
    >
        <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
                clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
        />
    </div>
)

export default function DataWhoAmI() {
    return (
        <div className="m-48 justify-center mx-auto max-w-7xl px-6 lg:px-8">
            {backgroundTop}
            <h1 className="mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-20 mx-auto">Qui suis-je ?</h1>
            <br/>
            <br/>
            <div className="flex flex-col sm:flex-row-reverse">
                <div className="grow-[2] text-center">
                    <img
                        className="mx-auto"
                        src="/photo-alex.png"
                        width={300}
                        height={150}
                        alt="Profile" />
                </div>
                <div className="grow-[3] max-w-prose">
                    <p className="text-justify justify-center mx-auto">
                        Je m'appelle Alexandra et je suis passionnée par les multiples facettes de l'humain et de la psychologie.
                        <br/>
                        Mon parcours académique reflète cette passion : je suis titulaire d'un master de l'enseignement ainsi que d'une licence en psychologie.
                        <br/>
                        <br/>
                        Mon voyage dans le domaine de la psychologie a débuté alors que j'exerçais en tant que développeuse web.
                        <br/>
                        Bien que j'ai toujours eu une affinité pour la technologie, j'ai ressenti le besoin profond d'explorer davantage le monde intérieur des individus.
                        <br/>
                        C'est ainsi que j'ai entrepris des études en psychologie en parallèle avec mon métier initial.
                        <br/>
                        Cette décision a enrichi mon parcours de manière extraordinaire.
                        <br/>
                        <br/>
                        En combinant mes compétences techniques avec une compréhension approfondie de la psychologie humaine,
                        j'ai trouvé ma véritable passion : aider les gens à surmonter leurs défis émotionnels et psychologiques.
                        <br/>
                        Mon expérience en développement web m'a également dotée d'aptitudes de communication et de résolution de problèmes,
                        que j'applique aujourd'hui dans ma pratique de psychopraticienne.
                        <br/>
                        <br/>
                        Ma vision en tant que psychopraticienne est de créer un espace bienveillant et sans jugement où vous pouvez explorer vos pensées,
                        vos émotions et vos préoccupations les plus profondes. J'utilise une approche holistique,
                        en combinant différentes méthodes thérapeutiques adaptées à vos besoins uniques.
                        <br/>
                        Mon objectif est de vous accompagner dans votre cheminement vers un bien-être mental et émotionnel durable.
                        <br/>
                        <br/>
                        En choisissant mes services de psychopraticienne, vous bénéficierez d'une expérience empreinte d'empathie,
                        de respect et de confidentialité. Que vous fassiez face à des défis spécifiques tels que le stress, l'anxiété,
                        la gestion des émotions ou que vous souhaitiez simplement mieux vous comprendre,
                        je suis là pour vous soutenir à chaque étape du chemin.
                        <br/>
                        <br/>
                        N'hésitez pas à me contacter pour planifier une séance. Ensemble,
                        nous pouvons travailler vers un avenir plus épanouissant et équilibré.
                        <br/>
                        <br/>
                        Au plaisir de vous accompagner dans votre voyage vers le mieux-être.
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        Alexandra
                    </p>
                </div>
            </div>
            {backgroundBottom}
        </div>

    )
}