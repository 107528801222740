import React from 'react';

const backgroundTop = (<div
    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    aria-hidden="true"
>
    <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
            clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
    />
</div>)

const backgroundBottom = (
    <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
    >
        <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
                clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
        />
    </div>
)
export default function DataRdv() {
    return (
           <div className="m-16 mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
               {backgroundTop}
               <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
                   <div>
                       <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-8">
                           Prendre Rendez-vous
                       </h1>
                   </div>
                   <br/>
                   <br/>
                   <div>
                       La consultation de 25 min est au tarif de  35 euros.
                   </div>
                   <div>
                       La consultation de 1h est au tarif de 65 euros.
                   </div>
                   <div>
                       Où ? En ligne exclusivement
                   </div>
                   <br/>
                   <h2 className="font-bold underline" >Règlement :</h2>
                   <div>
                       Le règlement s'effectuera par paiement carte à l'issue de la prise de rendez-vous.
                   </div>
                    <br/>
                    <br/>
                   <div>
                       <span className="font-bold underline">
                         A noter :
                       </span>
                       <p>
                           Les consultations annulées plus de 48h à l'avance seront remboursées.
                       Les autres consultations seront facturées.
                       </p>
                   </div>
               </div>

               <div className="calendly-inline-widget min-w-320 h-64  mx-auto flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0
            lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32"
                    data-url="https://calendly.com/contact-z3w?hide_landing_page_details=1&hide_gdpr_banner=1">
               </div>
               {backgroundBottom}
           </div>
    )
}