import React from 'react';
import BlogContent from "./content/blog-content";


let articles = [
    {
        path: "blog-content",
        element: <BlogContent />,
    }
]

articles = articles.map(article => {
    article.path = `/blog/${article.path}`
    return article
})

export default articles