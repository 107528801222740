import React from "react";

const backgroundTop = (<div
    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    aria-hidden="true"
>
    <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
            clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
    />
</div>)


const backgroundBottom = (
    <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
    >
        <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
                clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
        />
    </div>
)

export default function ConsultationInLine() {
    return (
        <div className="relative isolate overflow-hidden bg-white">
            {backgroundTop}
            <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">

                    <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-20">
                        La consultation en ligne
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600 mb-20">
                        Opter pour une consultation en ligne vous offre flexibilité, confort et confidentialité.
                        Vous pouvez bénéficier de notre soutien professionnel sans quitter votre environnement familier,
                        et cela dans le plus grand respect de votre vie privée.
                        <br /><br />
                    </p>
                    <div className="flex items-center gap-x-6 mt-6 justify-center">
                        <a
                            href="https://calendly.com/alex-campari-psy"
                            className="rounded-md bg-orange-900 px-3.5 py-2.5 text-sm
                            font-semibold text-white shadow-sm hover:bg-orange-800
                            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                            focus-visible:outline-blue-600 sm:text-2xl"
                        >
                            Prendre Rendez-vous
                        </a>
                    </div>
                </div>
                <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                    <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                        <div className="-m-2 bg-gray-900/5 p-2 lg:-m-4 lg:p-4">
                            <img
                                src="/consultation-online.webp"
                                alt="App screenshot"
                                width={1432}
                                height={800}
                                className="w-[40rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {backgroundBottom}
        </div>
    )
}
