import React from "react";

const backgroundTop = (<div
    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    aria-hidden="true"
>
    <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
            clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
    />
</div>)

const backgroundBottom = (
    <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
    >
        <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#c45824] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
                clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
        />
    </div>
)

export default function Hero() {
    return (
        <div className="relative isolate overflow-hidden bg-white">
            {backgroundTop}
            <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:flex  lg:py-40 justify-center gap-10">
                <div className="mx-40">
                    <img
                        src="/photo-alex.png"
                        width={300}
                        height={150}
                        alt="Profile"/>
                </div>
                <div className="lg:pt-8 flex-auto text-gray-900 justify-center">
                    <h1 className="mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                        Alex Campari
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600 sm:text-3xl mb-12">
                        Psychopraticienne en ligne
                    </p>
                    <p>Je suis Alexandra Campari, psychopraticienne. </p>
                    <p>
                        J'apporte mon soutien aux personnes qui ressentent le besoin de s'exprimer, <br/>
                        d'être écoutées et accompagnées sans jugement, à tout moment de leur vie.
                    </p>

                    <div className="flex items-center gap-x-6 mt-6 mt-20  mb-32">
                        <a
                            href="https://calendly.com/alex-campari-psy"
                            className="rounded-md bg-orange-900 px-3.5 py-2.5 text-sm
                            font-semibold text-white shadow-sm hover:bg-orange-800
                            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                            focus-visible:outline-blue-600 sm:text-xl"
                        >
                            Prendre Rendez-vous
                        </a>
                    </div>
                </div>
            </div>
            {backgroundBottom}
        </div>
    )
}
