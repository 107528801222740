import React from "react";

function accept() {
    return <img
        src="/accept.png"
        alt="App screenshot"
        width={1432}
        height={800}
        className="w-[40rem]"
    />
}

const reasons = [
    {
        name: 'Gestion des émotions et/ou de stress',
        icon: accept,
    },
    {
        name: 'Difficultés à vous épanouir dans vos relations',
        icon: accept,
    },
    {
        name: "Sentiment de décalage avec votre entourage, sentiment d'incompréhension, de ne pas être écouté",
        icon: accept,
    },
    {
        name: "Reproche trop émotif ou à l'inverse manque d'empathie",
        icon: accept,
    },
    {
        name: 'Insatisfaction chroniques',
        icon: accept,
    },
]

export default function ReasonsForConsultation() {
    return (
        <div className="mx-auto max-w-7xl px-6 lg:px-8 tracking-tight flex-row items-center mb-20">
            <h1 className="mt-10 text-xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-20 justify-center">
                Les motifs de consultations
            </h1>
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:gap-y-16 justify-center">
                {reasons.map((reason) => (
                    <div key={reason.name} className="relative pl-16">
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div
                                className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg">
                                <reason.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </div>
                            {reason.name}
                        </dt>
                    </div>
                ))}
            </dl>
        </div>
    )
}