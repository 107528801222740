module.exports =  {
    logo: {
        img: "",
        href: "/"
    },
    companyName: "Alex-Campari-Psy",
    navigation: [
        { name: 'Accueil', href: '/' },
        { name: 'Qui suis-je ?', href: '/qui-suis-je' },
        { name: 'Contact', href: '/contact' },
        { name: 'Prendre RDV', href: 'https://calendly.com/alex-campari-psy', isButton: true },
    ]
}