import Header from "../shared/section/header";
import Footer from "../shared/section/footer";
import BlogStructure from "./section/blog-structure";
import React from "react";

export default function Blog() {
    return (
        <div>
            <Header />
            <BlogStructure />
            <Footer />
        </div>
    )
}