module.exports = {
    faqs: [
        {
            question: 'Qu\'est-ce que la psychopraticienne fait exactement ?',
            answer:
                'En tant que psychopraticienne, mon rôle est de vous accompagner dans l\'exploration de vos pensées, émotions et comportements.' +
                'Je vous offre un espace sécurisé pour parler de vos préoccupations, travailler sur vos défis émotionnels et développer des stratégies pour améliorer votre bien-être mental.',
        },
        {
            question: 'Comment se déroule une séance avec vous ?',
            answer:
                'Une séance typique commence par une discussion ouverte sur ce que vous ressentez et sur les sujets que vous souhaitez aborder.' +
                ' Je vous écoute attentivement et vous guide à travers des techniques thérapeutiques adaptées à vos besoins. ' +
                'Chaque séance est personnalisée pour vous aider à atteindre vos objectifs.',
        },
        {
            question: 'Est-ce que tout ce que je partage reste confidentiel ?',
            answer:
                'Absolument. La confidentialité est une priorité absolue dans mon approche thérapeutique. ' +
                'Tout ce que vous partagez lors de nos séances reste strictement confidentiel, ' +
                'sauf dans les cas où votre sécurité ou celle d\'autrui est en jeu, conformément aux normes éthiques et légales.',
        },
        {
            question: 'Comment puis-je savoir si la thérapie convient pour moi ?',
            answer:
                'Si vous cherchez à mieux comprendre vos émotions, à gérer le stress, ' +
                'à surmonter des obstacles ou à améliorer votre bien-être général, la thérapie peut vous être bénéfique.' +
                'Nous pouvons discuter de vos besoins lors d\'une première consultation pour déterminer si c\'est la bonne option pour vous.',
        },
        {
            question: 'Quelle est la durée d\'une thérapie ?',
            answer:
                'La durée de la thérapie varie en fonction de vos objectifs et de vos besoins individuels. ' +
                'Certaines personnes trouvent des solutions en quelques séances, tandis que d\'autres préfèrent un suivi plus prolongé.' +
                'Nous évaluerons régulièrement vos progrès et ajusterons le plan en conséquence.',
        },
        {
            question: 'Proposez-vous des séances en ligne uniquement ?',
            answer:
                'Oui, je propose exclusivement des séances en ligne pour votre confort et votre flexibilité. ' +
                'Cette approche vous permet de bénéficier de l\'accompagnement dont vous avez besoin sans vous déplacer.',
        },
        {
            question: 'Comment puis-je prendre rendez-vous ?',
            answer:
                'Pour prendre rendez-vous, vous pouvez me contacter par le biais du formulaire de contact, ' +
                'ou bien par mail, ou encore par téléphone',
        },
        {
            question: 'Comment puis-je me préparer à ma première séance ?',
            answer:
                'Avant notre première séance, prenez un moment pour réfléchir à ce que vous aimeriez aborder. ' +
                'Vous n\'avez pas besoin d\'être parfaitement organisé - simplement ouvert à la conversation. ' +
                'Nous discuterons de tout le reste lors de notre première rencontre.',
        }
    ]
}