import React from 'react';
import Header from "../shared/section/header";
import Footer from "../shared/section/footer";
import ContactForm from "./section/contact-form";

export default function Contact() {
  return (
  <div>
    <Header />
    <ContactForm />
    <Footer />
  </div>
  )
}
